<template>
    <div class="container-mf-control-panel">
      <dashboard-layout></dashboard-layout>
    </div>
</template>

<script>
    // import 'sweetalert2/dist/sweetalert2.css'
    // import 'vue-notifyjs/themes/default.css'
    import DashboardLayout from '@/layout/DashboardLayout.vue'

    export default {
        name: "App",
        props: {
            token: String,
        },
        components: {
            DashboardLayout,
        },
    }
</script>

<style>
#app {
    position: absolute;
}
/*
.card .card-body {
    padding: 0px !important;
} */
</style>


