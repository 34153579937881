<template>
  <div>
    <!-- Fila 1 -->
    <div class="mx-3 mt-3">
      <b-card-group deck>
        <b-card header="Estado" bg-variant="light" class="font-weight-bolder" align="center">
          <b-card-text>
            <SemiCircle :chartData="chartData.dataPieState" :chartConfig="chartConfig.configPie"></SemiCircle>
          </b-card-text>
        </b-card>
        <b-card header="Actividad" bg-variant="light" class="font-weight-bolder" align="center">
          <b-card-text>
            <SemiCircle :chartData="chartData.dataPieMovement" :chartConfig="chartConfig.configPie"></SemiCircle>
          </b-card-text>
        </b-card>
        <b-card header="Velocidad" bg-variant="light" class="font-weight-bolder" align="center">
          <b-card-text>
            <SemiCircle :chartData="chartData.dataPieSpeed" :chartConfig="chartConfig.configPie"></SemiCircle>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>

    <!-- Fila 2 -->
    <div class="mx-3 my-3">
      <b-card-group deck>
        <b-card header="Km Recorridos" bg-variant="light" class="font-weight-bolder" align="center">
          <b-card-text>
            <Column :chartData="chartData.dataColumnKm" :chartConfig="chartConfig.configColumn"></Column>
          </b-card-text>
        </b-card>
        <b-card header="Horas de Uso" bg-variant="light" class="font-weight-bolder" align="center">
          <b-card-text>
            <Column :chartData="chartData.dataColumnHs" :chartConfig="chartConfig.configColumn"></Column>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import '@/assets/css/common.css'

import SemiCircle from "@/components/HighChart/SemiCircle"
import Column from "@/components/HighChart/Column"

import chartData from "./data"
import chartConfig from "./config"

export default {
	components: {
    SemiCircle,
    Column
	},
	data() {
		return {
      chartData: chartData,
      chartConfig: chartConfig
		}
	},
	methods: {
  	}
}
</script>
