<template>
  <div>
    <div class="main-panel">
      <dashboard-content id="content">
      </dashboard-content>
    </div>
  </div>
</template>

<script>
  import DashboardContent from './Content.vue'
  // import { mapState } from "vuex"

  export default {
    mounted() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    components: {
      // TopNavbar,
      // ContentFooter,
      DashboardContent,
      // UserMenu,
    },
    methods: {
      handleResize() {
        // let navbarHeight = document.getElementById("navbar").offsetHeight
        let contentHeight = window.offsetHeight - 0 //navbarHeight
        document.getElementById("content").style.height = contentHeight + 'px'
        // document.getElementById("content").style.marginTop = 80 + 'px'
      },
    }
  }
</script>

