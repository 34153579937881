import Vue from "vue";
import HighchartsVue from "highcharts-vue";

import Highcharts from 'highcharts';

import exportingInit from 'highcharts/modules/exporting'
import loadDrillDown from 'highcharts/modules/drilldown'

loadDrillDown(Highcharts)
exportingInit(Highcharts)

Vue.use(HighchartsVue, { Highcharts });