const dataPieState = {
    title: 'Estado',
    tooltip: '<b>{point.y}</b> ({point.percentage:.1f}%)',
    seriesName: 'Estado',
    seriesData: [
      ['Normal', 60.0],
      ['Service', 30.0],
      ['Sin Cx', 10.0],
      ['Office', 4.0],
      ['Technic', 2.0],
    ],
    colors: ['#31B404', '#F4FA58', '#FA5858', '#2E64FE', '#01DFD7',
    '#01DFD7', '#E6E6E6', '#BDBDBD', '#585858', '#151515']
};

const dataPieMovement = {
  title: 'Actividad',
  tooltip: '<b>{point.y}</b> ({point.percentage:.1f}%)',
  seriesName: 'Actividad',
  seriesData: [
    ['Movimiento', 40.0],
    ['Detenido', 20.0]
  ],
  colors: ['#31B404', '#BDBDBD']
};

const dataPieSpeed = {
  title: 'Velocidad',
  tooltip: '<b>{point.y}</b> ({point.percentage:.1f}%)',
  seriesName: 'Velocidad',
  seriesData: [
    ['Normal', 30.0],
    ['Medio', 15.0],
    ['Rapido', 5.0]
  ],
  colors: ['#31B404', '#F4FA58', '#FA5858']
};

const dataColumnKm = {
  title: 'Kilometros Recorridos',
  yAxisTitle: '',
  tooltip: '<b>{point.y}</b>',
  serieName: 'Kilometros',
  seriesData: [
    {
      name: "Kilometros",
      colorByPoint: true,
      data: [
        {
          name: "DO",
          y: 62.74,
          drilldown: "DO"
        },
        {
          name: "LU",
          y: 10.57,
          drilldown: "LU"
        },
        {
          name: "MA",
          y: 7.23,
          drilldown: "MA"
        },
        {
          name: "MI",
          y: 65.58,
          drilldown: "MI"
        },
        {
          name: "JU",
          y: 24.02,
          drilldown: "JU"
        },
        {
          name: "VI",
          y: 21.92,
          drilldown: "VI"
        },
        {
          name: "SA",
          y: 17.62,
          drilldown: null
        }
      ]
    }
  ],
  colors: ['#31B404', '#F4FA58', '#FA5858', '#2E64FE', '#01DFD7', '#01DFD7', '#E6E6E6', '#BDBDBD'],
  seriesDrillDown: [
    {
      name: "DO",
      id: "DO",
      data: [
        [
          "v65.0",
          0.1
        ],
        [
          "v48.0",
          0.13
        ],
        [
          "v47.0",
          0.11
        ],
        [
          "v43.0",
          0.17
        ],
        [
          "v29.0",
          0.26
        ]
      ]
    },
    {
      name: "LU",
      id: "LU",
      data: [
        [
          "v58.0",
          1.02
        ],
        [
          "v54.0",
          0.1
        ],
        [
          "v52.0",
          0.95
        ],
        [
          "v51.0",
          0.15
        ],
        [
          "v47.0",
          0.12
        ]
      ]
    },
    {
      name: "MA",
      id: "MA",
      data: [
        [
          "v11.0",
          6.2
        ],
        [
          "v10.0",
          0.29
        ],
        [
          "v9.0",
          0.27
        ],
        [
          "v8.0",
          0.47
        ]
      ]
    },
    {
      name: "MI",
      id: "MI",
      data: [
        [
          "v11.0",
          3.39
        ],
        [
          "v10.1",
          0.96
        ],
        [
          "v10.0",
          0.36
        ]
      ]
    },
    {
      name: "JU",
      id: "JU",
      data: [
        [
          "v16",
          2.6
        ],
        [
          "v15",
          0.92
        ],
        [
          "v14",
          0.4
        ],
        [
          "v13",
          0.1
        ]
      ]
    },
    {
      name: "VI",
      id: "VI",
      data: [
        [
          "v50.0",
          0.96
        ],
        [
          "v49.0",
          0.82
        ],
        [
          "v12.1",
          0.14
        ]
      ]
    }
  ]
};

const dataColumnHs = {
  title: 'Horas de Uso',
  yAxisTitle: '',
  tooltip: '<b>{point.y}</b>',
  serieName: 'Horas',
  seriesData: [
    {
      name: "Horas",
      colorByPoint: true,
      data: [
        {
          name: "DO",
          y: 56.6,
          drilldown: "DO"
        },
        {
          name: "LU",
          y: 35.57,
          drilldown: "LU"
        },
        {
          name: "MA",
          y: 73.23,
          drilldown: "MA"
        },
        {
          name: "MI",
          y: 24.58,
          drilldown: "MI"
        },
        {
          name: "JU",
          y: 45.02,
          drilldown: "JU"
        },
        {
          name: "VI",
          y: 12.92,
          drilldown: "VI"
        },
        {
          name: "SA",
          y: 17.62,
          drilldown: null
        }
      ]
    }
  ],
  colors: ['#31B404', '#F4FA58', '#FA5858', '#2E64FE', '#01DFD7', '#01DFD7', '#E6E6E6', '#BDBDBD'],
  seriesDrillDown: [
    {
      name: "DO",
      id: "DO",
      data: [
        [
          "v65.0",
          0.1
        ],
        [
          "v48.0",
          0.13
        ],
        [
          "v47.0",
          0.11
        ],
        [
          "v43.0",
          0.17
        ],
        [
          "v29.0",
          0.26
        ]
      ]
    },
    {
      name: "LU",
      id: "LU",
      data: [
        [
          "v58.0",
          1.02
        ],
        [
          "v54.0",
          0.1
        ],
        [
          "v52.0",
          0.95
        ],
        [
          "v51.0",
          0.15
        ],
        [
          "v47.0",
          0.12
        ]
      ]
    },
    {
      name: "MA",
      id: "MA",
      data: [
        [
          "v11.0",
          6.2
        ],
        [
          "v10.0",
          0.29
        ],
        [
          "v9.0",
          0.27
        ],
        [
          "v8.0",
          0.47
        ]
      ]
    },
    {
      name: "MI",
      id: "MI",
      data: [
        [
          "v11.0",
          3.39
        ],
        [
          "v10.1",
          0.96
        ],
        [
          "v10.0",
          0.36
        ]
      ]
    },
    {
      name: "JU",
      id: "JU",
      data: [
        [
          "v16",
          2.6
        ],
        [
          "v15",
          0.92
        ],
        [
          "v14",
          0.4
        ],
        [
          "v13",
          0.1
        ]
      ]
    },
    {
      name: "VI",
      id: "VI",
      data: [
        [
          "v50.0",
          0.96
        ],
        [
          "v49.0",
          0.82
        ],
        [
          "v12.1",
          0.14
        ]
      ]
    }
  ]
};
export default { dataPieState, dataPieMovement, dataPieSpeed, dataColumnKm, dataColumnHs };