const configPie = {
  pieStartAngle: -90,
  pieEndAngle: 270,
  seriesInnerSize: "0%"
};

const configColumn = {
  LabelsFormat: '{point.y}',
  // pieEndAngle: 270,
  // seriesInnerSize: "50%"
};

export default { configPie, configColumn };