import Home from '@/views/Home/Index'

const routes = [
  {
    path: '/panel',
    name: 'Tablero de Control',
    component: Home,
    meta: {
        public: false,          // Solo accede si está logueado
        onlyWhenLoggedOut: false
    }
  },
  {path: '*', component: Home}   // cambiar a not found?
];

export default routes
