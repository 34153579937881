<template>
  <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
</template>

<script>
import Highcharts from 'highcharts';
import dataPieState from '../../views/Home/data'

export default {
  name: 'SemiCircle',
  data() {
    return {
      chartOptions: {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        credits: {
          enabled: false
        },
        title: {
          // text: this.chartData.title,
          text: '',
          style: {
              display: 'none'
          },
          align: "center",
          verticalAlign: "middle"
        },
        tooltip: {
          pointFormat: this.chartData.tooltip,
          shared: true
        },
        accessibility: {
          point: {
            valueSuffix: ""
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              distance: -50,
              style: {
                fontWeight: "bold",
                color: "white"
              }
            },
            startAngle: this.chartConfig.pieStartAngle,
            endAngle: this.chartConfig.pieEndAngle,
            center: ["50%", "50%"]
          }
        },
          colors: Highcharts.map(this.chartData.colors, function (color) {
          return {
            radialGradient: {
              cx: 0.5,
              cy: 0.3,
              r: 0.7
            },
            stops: [
              [0, color],
              [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
            ]
          };
        }),
        series: [
          {
            type: "pie",
            name: this.chartData.seriesName,
            innerSize: this.chartConfig.seriesInnerSize,
            data: this.chartData.seriesData
          }
        ]
      }
    };
  },
  props: {
    chartData: {
      required: true,
      type: Object
    },
    chartConfig: {
      required: true,
      type: Object
    }
  }
};
</script>
