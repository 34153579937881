<template>
  <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
</template>

<script>
export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          // text: this.chartData.title,
          text: '',
          style: {
              display: 'none'
          }
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: this.chartData.yAxisTitle,
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: this.chartConfig.LabelsFormat
            }
          }
        },
        tooltip: {
          // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: this.chartData.tooltip
        },
        colors: this.chartData.colors,
        series: this.chartData.seriesData,
        drilldown: {
          series: this.chartData.seriesDrillDown,
          drillUpButton: {
            relativeTo: 'spacingBox',
            position: {
                y: 30,
                x: -10
            },
            theme: {
                r: 4,
            }
          }
        },
        lang: {
          drillUpText: '< Volver a {series.name}'
        },
      }
    };
  },
  props: {
    chartData: {
      required: true,
      type: Object
    },
    chartConfig: {
      required: true,
      type: Object
    }
  }
};
</script>
