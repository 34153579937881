import "./set-public-path";
import singleSpaVue from "single-spa-vue";
import Vue from 'vue'

import './pollyfills'

import "./plugins/bootstrapVue";
import "./plugins/highchartsVue";

////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////////
import { TokenService } from "@ingeo-mf/styleguide";
//////////

import App from './App.vue'
import store from './store'
import router from "./router";

console.log("Running mode: ", process.env.VUE_APP_DEVMODE);

////////////////////////////////////////////////////
/////////
/////////
////////
////////////
////////////
/////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////
////
/////////////
////////////////
////
////////////////////////
//////////////////

/////////

Vue.config.productionTip = false;
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      TokenService.saveToken(this.token);
      return h(App, { props: { token: this.token } });
    },
    router,
    store,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

//////////
